.logo {
  display: block;
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
  max-width: 150px;
}

@media only screen and (min-width: 576px) {
  .logo {
    margin-top: 20px;
    margin-left: 60%;
    max-width: 150px;
  }
}
